import React from 'react';
import './App.css';
import Navigation from './navigation';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const lngs = {
  en: { img: <img id="en" width="20" height="20" src="/americanFlag.webp" alt="dataPrivacyPolicy"/>},
  de: { img: <img id="de" width="20" height="20" src="/germanFlag.webp" alt='americanFlag'/>}
};

var currentLanguage = <img id="de" width="20" height="20" src="/germanFlag.webp" alt='americanFlag'/>;

function changeLanguage(lng){
  currentLanguage = lngs[lng].img;
}

function App() {
  const{ i18n } = useTranslation();
  return (
    <div className="App">
            <div className='languageChange'>
              <ul className='languages'>
                <li id="currentLanguage">
                  {currentLanguage}
                  <ul>
                    {Object.keys(lngs).map((lng) => (
                      <li key={lng} onClick={() => {i18n.changeLanguage(lng); changeLanguage(lng)}} disabled={i18n.resolvedLanguage === lng}>{lngs[lng].img}</li>
                    ))}
                  </ul>
                </li>
              </ul>
            </div>
      <Navigation></Navigation>
    </div>
  );
}

export default withTranslation() (App);
