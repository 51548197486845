import { useTranslation } from 'react-i18next';

const DataPrivacyPolicy = () => {
    const{ t } = useTranslation();
    return (
        <div>
            <h2 className="pageTheme">{t('dataPrivacyPolicy')}</h2>
            <div className='dataPrivacyPolicy'>
                <h1>{t('dataProtectionTheme')}</h1>
                <h2>{t('generalInformationTheme')}</h2>
                <p>{t('generalInformationText')}</p>
                <h2>{t('dataCollection')}</h2>
                <h3>{t('1dataCollectionTheme')}</h3>
                <p>{t('1dataCollectionText')}</p>
                <h3>{t('2dataCollectionTheme')}</h3>
                <p>{t('2dataCollectionText')}</p>
                <h3>{t('3dataCollectionTheme')}</h3>
                <p>{t('3dataCollectionText')}</p>
                <h3>{t('4dataCollectionTheme')}</h3>
                <p>{t('4dataCollectionText')}</p>
                <h2>{t('tools')}</h2>
                <p>{t('toolsText')}</p>

                <h1>{t('hosting')}</h1>
                <p>{t('hostingText')}</p>
                <h2>{t('externalHosting')}</h2>
                <p>{t('externalHostingText')}</p>
                <p>{t('externalHostingAddress')}</p>
                <ul>
                    <li>{t('externalHostingAddress1')}</li>
                    <li>{t('externalHostingAddress2')}</li>
                    <li>{t('externalHostingAddress3')}</li>
                    <li>{t('externalHostingAddress4')}</li>
                    <li>{t('externalHostingAddress5')}</li>
                    <li>{t('externalHostingAddress6')}</li>
                    <li>{t('externalHostingAddress7')}</li>
                </ul>
                <h2>{t('orderProcessing')}</h2>
                <p>{t('orderProcessingText')}</p>

                <h1>{t('mandatoryInformation')}</h1>
                <h2>{t('dataProtection')}</h2>
                <p>{t('dataProtectionText')}</p>
                <h2>{t('responsibleTheme')}</h2>
                <p>{t('responsibleText')}</p>
                <ul>
                    <li>{t('responsibleText1')}</li>
                    <li>{t('responsibleText2')}</li>
                    <li>{t('responsibleText3')}</li>
                    <li>{t('responsibleText4')}</li>
                    <li>{t('responsibleText5')}</li>
                    <li>{t('responsibleText6')}</li>
                </ul>
                <p>{t('responsibleTextLast')}</p>
                <h2>{t('storagePeriod')}</h2>
                <p>{t('storagePeriodText')}</p>
                <h2>{t('legalBasis')}</h2>
                <p>{t('legalBasisText')}</p>
                <h2>{t('recipient')}</h2>
                <p>{t('recipientText')}</p>
                <h2>{t('revocation')}</h2>
                <p>{t('revocationText')}</p>
                <h2>{t('Art.21DSGVO')}</h2>
                <p>{t('Art.21DSGVOText')}</p>
                <h2>{t('rightOfAppeal')}</h2>
                <p>{t('rightOfAppealText')}</p>

                <h2>{t('dataPortability')}</h2>
                <p>{t('dataPortabilityText')}</p>
                <h2>{t('information')}</h2>
                <p>{t('informationText')}</p>
                <h2>{t('processing')}</h2>
                <p>{t('processingText')}</p>
                <ul>
                    <li>{t('processingText1')}</li>
                    <li>{t('processingText2')}</li>
                    <li>{t('processingText3')}</li>
                    <li>{t('processingText4')}</li>
                </ul>
                <p>{t('processingTextLast')}</p>
                <h2>{t('SSL')}</h2>
                <p>{t('SSLText')}</p>

                <h1>{t('sozialeMedien')}</h1>
                <h2>{t('elements')}</h2>
                <p>{t('elementsText')}</p>

                <h1>{t('plugins')}</h1>
                <h2>{t('googleFonts')}</h2>
                <p>{t('googleFontsText')}</p>
                <h2>{t('Fonts')}</h2>
                <p>{t('FontsText')}</p>
                <h2>{t('googleMaps')}</h2>
                <p>{t('googleMapsText')}</p>

                <p>{t('source')}</p>
                <p>{t('sourceText')}</p>
            </div>
        </div>
    );
}
export default DataPrivacyPolicy;