import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nextBrowserLanguagedetector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';

i18n
    .use(initReactI18next)
    .use(i18nextBrowserLanguagedetector)
    .use(Backend)
    .init({
        fallbackLng: 'de'
    });
    export default i18n;