import { useTranslation } from 'react-i18next';

const AboutMe = () => {
    const{ t } = useTranslation();
    return (
        <div>
            <h2 className="pageTheme">{t('aboutMe')}</h2>
            <div className='aboutMe'>
                <div className='aboutMeFirstDiv'>
                    <div className='imgsDiv'>
                        <div id='squareOne' className='square'></div>
                        <img id="profilPictureOne" className="profilPicture" width="150" height="150" src="./logo.webp" alt="user-female-circle"/>
                        <div id='squareTwo' className='square'></div>
                    </div>
                    <div className='aboutMeText'>
                        <p>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                            At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. 
                        </p>
                    </div>
                </div>
                <div className='aboutMeSecondDiv'>
                    <div id="imgsDivTwo" className='imgsDiv'>
                        <div id='squareThree' className='square'></div>
                        <img id="profilPictureTwo" className="profilPicture" width="150" height="150" src="./logo.webp" alt="user-female-circle"/>
                        <div id='squareFourth' className='square'></div>
                    </div>
                    <div id="aboutMeTextTwo" className='aboutMeText'>
                        <p>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                            At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. 
                        </p>
                    </div>
                </div>
                <div className='aboutMeThirdDiv'>
                    <p>{t('personalConversation1')}<li className='link' onClick={()=>{document.getElementsByTagName("li")[4].click();}}>{t('personalConversation2')}</li>{t('personalConversation3')}</p>
                </div>
            </div>
        </div>
    );
}
export default AboutMe;