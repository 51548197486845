import React from "react";

import Home from './home';
import Industry from './industry';
import HomeDesign from './homedesign';
import AboutMe from './aboutme';
import Contact from './contact';
import DataPrivacyPolicy from './dataPrivacyPolicy';
import LegalNotice from "./legalNotice";
import { withTranslation } from 'react-i18next';

const body = [
    <Home></Home>,
    <Industry></Industry>,
    <HomeDesign></HomeDesign>,
    <AboutMe></AboutMe>,
    <Contact></Contact>,
    <LegalNotice></LegalNotice>,
    <DataPrivacyPolicy></DataPrivacyPolicy>
]

class Navigation extends React.Component{
    constructor(props){
        super(props);
        this.state={
            page: body[0] 
        }
        this.pageSwitch = this.pageSwitch.bind(this);
    }

    pageSwitch(event){
        this.closeNav();
        if(event.target.className === "logo"){
            this.setState({page: body[0]});
        }else{
            this.setState({page: body[event.target.value]});
        }
    }

    openNav(){
        let header = document.getElementsByTagName("header")[0];
        let main = document.getElementsByTagName("main")[0];
        let footer = document.getElementsByTagName("footer")[0];
        
        main.classList.add("opacity");
        footer.classList.add("opacity");
        header.classList.add("opacity");

        document.getElementsByTagName("nav")[0].style.width = "40%";
    }

    closeNav() {
            let header = document.getElementsByTagName("header")[0];
            let main = document.getElementsByTagName("main")[0];
            let footer = document.getElementsByTagName("footer")[0];

            header.classList.remove("opacity");
            main.classList.remove("opacity");
            footer.classList.remove("opacity");

            let width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            if(width<=700){
                document.getElementsByTagName("nav")[0].style.width = "0";
            }
        }

    render(){
        return(
            <div>
                <nav>
                    <ul>
                        <li className="navCloseBtn" onClick={this.closeNav}>×</li>
                        <li onClick={this.pageSwitch} value='1' className="industry">{this.props.t('industry')}</li>
                        <li onClick={this.pageSwitch} value='2' className="homeDesign">{this.props.t('homeDesign')}</li>
                        <li onClick={this.pageSwitch} value='3'>{this.props.t('aboutMe')}</li>
                        <li onClick={this.pageSwitch} value='4'>{this.props.t('contact')}</li>
                    </ul>
                </nav>
                <svg className="navOpenBtn" onClick={this.openNav} xmlns="http://www.w3.org/2000/svg" height="28px" width="24px" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>
                <header>
                    <img  onClick={this.pageSwitch} value='0' className='logo' src="./logo.webp" alt="TP Maas"/>
                </header>
                <main>
                    <div className="mainWrapper">
                        {this.state.page}
                    </div>
                </main>
                <footer>
                    <div className="socialMedia">
                        <a href="https://www.instagram.com/tp_maas?igsh=YmhzNXkyaDBzcjRm&utm_source=qr" aria-label={this.props.t('instagram')}><svg xmlns="http://www.w3.org/2000/svg" height="30" width="28" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a>
                        <a href="https://www.linkedin.com/company/technical-productdesign-maas/" aria-label={this.props.t('linkedin')}><svg xmlns="http://www.w3.org/2000/svg" height="30" width="28" viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg></a>
                        <a href="https://www.xing.com/profile/Janina_Maas22" aria-label={this.props.t('xing')}><svg xmlns="http://www.w3.org/2000/svg" height="30" width="28" viewBox="0 0 384 512"><path d="M162.7 210c-1.8 3.3-25.2 44.4-70.1 123.5-4.9 8.3-10.8 12.5-17.7 12.5H9.8c-7.7 0-12.1-7.5-8.5-14.4l69-121.3c.2 0 .2-.1 0-.3l-43.9-75.6c-4.3-7.8 .3-14.1 8.5-14.1H100c7.3 0 13.3 4.1 18 12.2l44.7 77.5zM382.6 46.1l-144 253v.3L330.2 466c3.9 7.1 .2 14.1-8.5 14.1h-65.2c-7.6 0-13.6-4-18-12.2l-92.4-168.5c3.3-5.8 51.5-90.8 144.8-255.2 4.6-8.1 10.4-12.2 17.5-12.2h65.7c8 0 12.3 6.7 8.5 14.1z"/></svg></a>
                    </div>
                    <ul className="impressum">
                        <li onClick={this.pageSwitch} value='5'>{this.props.t('legalNotice')}</li>
                        <li id="dataPrivacyPolicy" onClick={this.pageSwitch} value='6'>{this.props.t('dataPrivacyPolicy')}</li>
                    </ul>
                </footer>
            </div>
        )
    }
}export default withTranslation() (Navigation);