import { useTranslation } from 'react-i18next';

const LegalNotice = () => {
    const{ t } = useTranslation();
    return (
        <div>
            <h2 className="pageTheme">{t('legalNotice')}</h2>
            <div className='legalNotice'>
                <h1>{t("legalNoticeTheme")}</h1>
                <h2>{t("managingDirectors")}</h2>
                <p>{t("name")}</p>
                <h2>{t("address")}</h2>
                <ul>
                    <li>{t("street")}</li>
                    <li>{t("city")}</li>
                    <li>{t("country")}</li>
                </ul>
                <h2>{t("contact")}</h2>
                <ul>
                    <li>{t("e-mail")}</li>
                    <li>{t("phone")}</li>
                    <li>{t("website")}</li>
                </ul>
                <h2>{t("taxNumber")}</h2>
                <p>{t("number")}</p>
                <h1>{t("disclaimer")}</h1>
                <h2>{t("liabilityContent")}</h2>
                <p>{t("liabilityContentText")}</p>
                <h2>{t("liabilityLink")}</h2>
                <p>{t("liabilityLinkText")}</p>
                <h2>{t("copyright")}</h2>
                <p>{t("copyrightText")}</p>
            </div>
        </div>
    );
}
export default LegalNotice;