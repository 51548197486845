import React from "react";
import { withTranslation } from 'react-i18next';

const imagesButton = [
    "buttonIndustry320.webp",
    "buttonIndustry1200.webp",
    "buttonPrivat320.webp",
    "buttonPrivat1200.webp"
]
class Home extends React.Component{
    constructor(props){
        super(props);
        this.clickNav = this.clickNav.bind(this);
    }

    clickNav(event){
        console.log(event.target.className);
        switch(event.target.className){
            case "buttonIndustry": 
                document.getElementsByTagName("li")[4].click();
                break;
            case "fontIndustry": 
                document.getElementsByTagName("li")[4].click();
            break;
            case "buttonPrivat": 
                document.getElementsByTagName("li")[5].click();
                break;
            case "fontPrivat":
                document.getElementsByTagName("li")[5].click();
                break;
            default: 
                break;
        }
    }

    render(){
        return(
            <div>
                <h2 className="sloagen">dream big - design bigger</h2>
                <div className="navImgs">
                    <section>
                        <img rel="preload" onClick={this.clickNav} className='buttonIndustry' src={imagesButton[0]} srcSet={`${imagesButton[0]} 300w, ${imagesButton[1]} 1200w`} alt={this.props.t('buttonIndustry')}/>
                        <h3 className='fontIndustry' onClick={this.clickNav}>{this.props.t('industry')}</h3>
                    </section>
                    <section>
                        <img rel="preload" onClick={this.clickNav} className='buttonPrivat' src={imagesButton[2]} srcSet={`${imagesButton[2]} 300w, ${imagesButton[3]} 1200w`} alt={this.props.t('buttonPrivat')}/>
                        <h3 className='fontPrivat' onClick={this.clickNav}>{this.props.t('homeDesign')}</h3>
                    </section>
                </div>
            </div>
       );
    }
}export default withTranslation() (Home);