import React from "react";
import { withTranslation } from 'react-i18next';

class HomeDesign extends React.Component{
/*    constructor(props){
        super(props);
    }*/
    render(){
        return (
            <div>
                <h2 className="pageTheme">Home Design</h2>
                <h1>{this.props.t("inProgress")}</h1>
            </div>
        );
    }
}
export default withTranslation() (HomeDesign);