import React, { useRef } from "react";
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';

const Contact = () => { 
    const{ t } = useTranslation();
    
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_vit93o3', 'template_iwvkyvr', form.current, 'bi-sVk-HNGuIJY64A')
        .then((result) => {
            console.log(result.text);
            alert("E-mail sent.")
            let form = document.getElementsByTagName("form")[0];
            form.reset();
        }, (error) => {
            console.log(error.text);
            alert("E-mail failed.")
        })
    };
    return(
        <div>
            <h2 className="pageTheme">{t('contact')}</h2>
            <div className="contactPage">
                <ul className="contactAdress">
                    <li>Technical Productdesigne by Janina Maas</li>
                    <li>Finkenstr. 17</li>
                    <li>92555 Trausnitz</li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" className="mail" height="13" width="13" viewBox="0 0 512 512"><path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>janina.maas@tpmaas.com</li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" className="phone" height="13" width="9" viewBox="0 0 384 512"><path d="M16 64C16 28.7 44.7 0 80 0H304c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H80c-35.3 0-64-28.7-64-64V64zM224 448a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM304 64H80V384H304V64z"/></svg>015679 204454</li>
                </ul>
                <div className="contact">
                    <div className="mailForm">
                        <p className="contactMe">{t('sendMessage')}</p>
                        <form ref={form} onSubmit={sendEmail}>
                            <label>Name</label>
                            <input type="text" name="user_name" placeholder={t('yourName')} required/>
                            <label>E-mail</label>
                            <input type="email" name="user_email" placeholder={t('yourE-mail')} pattern="[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[a-z]{2,}$" required/>
                            <label>{t('message')}</label>
                            <textarea name="message" rows="6" placeholder={t('yourMessage')} required/>
                            <div className="checkbox">
                                <input type="checkbox" name="check" value={"check"} required/>
                                <p>{t('toReadBefore1')}<li className="link" onClick={()=>{document.getElementById("dataPrivacyPolicy").click()}}>{t('dataPrivacyPolicy')}</li>{t('toReadBefore2')}</p>
                            </div>
                            <input className="button" type="submit" value={t('send')}/>
                        </form>
                    </div>
                    <div className="map">
                        <p className="contactMe">{t('visitMe')}</p>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2589.977251296476!2d12.256382276131983!3d49.52270517142992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a0180548fbb06f%3A0xceee8908c9645899!2sFinkenstra%C3%9Fe%2017%2C%2092555%20Trausnitz%2C%20Deutschland!5e0!3m2!1sde!2smx!4v1703289493187!5m2!1sde!2smx" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="location"></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default Contact;